export default {
    methods: {
        handleSuccess(message){
             this.$toast.success(message);
        },
        handleError(message){
            this.$toast.error(message);
        },
        throwError(){
            this.$toast.error("Unknown Error Occured");
        }
    }
}